import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'  //引入状态机对象


Vue.use(VueRouter)

// 解决重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [

  {
    // 登录页
    path: '/',
    name: 'Login',
    component: ()=>import('../views/Login.vue')
  },
  {
    // 首页
    path: '/home',
    name: 'Index',
    component: ()=>import('../views/Index.vue'),
    meta: {
      requireAuth: true
    }
  },
    {
        // 章节
        path: '/chapter',
        name: 'Chapter',
        component: ()=>import('../views/Chapter.vue'),
        meta: {
            requireAuth: true
        }
    }, {
        // 章节练习
        path: '/chapterPractice',
        name: 'ChapterPractice',
        component: ()=>import('../views/ChapterPractice.vue'),
        meta: {
            requireAuth: true
        }
    },
    {
        // 随机练习
        path: '/randomPractice',
        name: 'RandomPractice',
        component: ()=>import('../views/RandomPractice.vue'),
        meta: {
            requireAuth: true
        }
    },
    {
        // 错题练习
        path: '/errorQuestPractice',
        name: 'ErrorQuestPractice',
        component: ()=>import('../views/ErrorQuestPractice.vue'),
        meta: {
            requireAuth: true
        }
    },  {
        // 我的收藏
        path: '/collectQuestPractice',
        name: 'CollectQuestPractice',
        component: ()=>import('../views/CollectQuestPractice.vue'),
        meta: {
            requireAuth: true
        }
    },
    {
      // 我的笔记
      path: '/noteDetail',
      name: 'NoteDetail',
      component: ()=>import('../views/NoteDetail.vue'),
      meta: {
          requireAuth: true
      }
  },
  {
    // 笔记详情
    path: '/myNote',
    name: 'MyNote',
    component: ()=>import('../views/MyNote.vue'),
    meta: {
        requireAuth: true
    }
},
    {
        // 统计
        path: '/statistics',
        name: 'Statistics',
        component: ()=>import('../views/Statistics.vue'),
        meta: {
            requireAuth: true
        }
    },{
        // 成绩单列表
        path: '/transcriptList',
        name: 'TranscriptList',
        component: ()=>import('../views/TranscriptList.vue'),
        meta: {
            requireAuth: true
        }
    },

  {
    // 基本信息/修改密码/答题记录
    path: '/userInfo',
    name: 'UserInfo',
    component: ()=>import('../views/UserInfo.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 单位介绍页
    path: '/company',
    name: 'Company',
    component: ()=>import('../views/Company.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 通知公告页
    path: '/notice',
    name: 'Notice',
    component: ()=>import('../views/Notice.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 通知公告详情页
    path: '/notice/noticedetail',
    name: 'Noticedetail',
    component: ()=>import('../views/Notice_detail.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 新闻资讯页
    path: '/news',
    name: 'News',
    component: ()=>import('../views/News.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 新闻详情页
    path: '/news/newsdetail',
    name: 'Newsdetail',
    component: ()=>import('../views/News_detail.vue'),
    meta: {
      requireAuth: true
    }
  },

  {
    // 课程学习页
    path: '/course',
    name: 'Course',
    component: ()=>import('../views/Course.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 课程详情页
    path: '/courseDetail/:id',
    name: 'CourseDetail',
    component: ()=>import('../views/Course_detail.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 文章学习页
    path: '/article',
    name: 'Article',
    component: ()=>import('../views/Article.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 文章学习详情页
    path: '/articleDetail/:id',
    name: 'ArticleDetail',
    component: ()=>import('../views/Article_detail.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 模拟考试页
    path: '/tryexam',
    name: 'Tryexam',
    component: ()=>import('../views/Tryexam.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 综合考试页
    path: '/exam',
    name: 'Exam',
    component: ()=>import('../views/Exam.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 题目解析页
    path: '/examanalysis',
    name: 'Examanalysis',
    component: ()=>import('../views/Exam_analysis.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    // 考试成绩页
    path: '/result',
    name: 'Result',
    component: ()=>import('../views/Result.vue'),
    meta: {
      requireAuth: true
    }
  },
    {
        // 404页
        path: '*',
        name: 'Nofound',
        component: ()=>import('../views/404.vue')
    },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




router.beforeEach((to, from, next) => {

  //解决页面跳转没有置顶问题
  document.body.scrollTop=0;
  document.documentElement.scrollTop=0;
  window.scrollY = 0;

  let token = window.sessionStorage.getItem("token")
  if (to.path !== '/') {
    if(token ){
      next();
    }
    else {

      next('/');
    }
  }  else {


    if(token){
      next('/home')
    }else{
      next();
    }

  }
})
export default router

